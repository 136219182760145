<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    content-class="volunteering"
  >
    <v-card class="print-modal">
      <v-toolbar dark color="primary">
        <v-btn @click="print">
          <Icon name="far fa-print" size="20" class="mr-2" />Imprimir
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark text @click="$emit('onClose', false)">
            <Icon name="fal fa-times" size="20" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="paper" id="printMeCalendar">
        <div class="display-flex align-center">
          <div class="flex-grow-1">
            <h1>{{ volunteering.fullName }}</h1>
            <h4>{{ attributeOffer.offerName }}</h4>
          </div>
          <div
            class="text-xs-right mr-4"
            v-if="attributeOffer.volunteeringType.name == 'Recorrente'"
          >
            <img src="/images/logo-gray.svg" alt />
            <h5>
              Voluntário até:
              <b class="text-blue">{{
                getRecurrentLimitDateText(
                  attributeOffer.volunteeringRecurrentTypes[0]
                )
              }}</b>
            </h5>
          </div>
        </div>
        <table class="table" width="100%" cellspacing="0" cellpadding="0">
          <tr>
            <th class="time"></th>
            <th>S</th>
            <th>T</th>
            <th>Q</th>
            <th>Q</th>
            <th>S</th>
            <th>S</th>
            <th>D</th>
          </tr>
          <tr v-for="time in times" :key="time.index">
            <td class="time">{{ time.hour }}</td>
            <td>
              <div class="box-date" v-if="isSelected('Segunda', time.hour)">
                <p>{{ time.hour }}</p>
                <p>
                  <b>Voluntáriado</b>
                </p>
                <p>
                  <small>{{ attributeOffer.offerName }}</small>
                </p>
              </div>
            </td>
            <td>
              <div class="box-date" v-if="isSelected('Terça', time.hour)">
                <p>{{ time.hour }}</p>
                <p>
                  <b>Voluntáriado</b>
                </p>
                <p>
                  <small>{{ attributeOffer.offerName }}</small>
                </p>
              </div>
            </td>
            <td>
              <div class="box-date" v-if="isSelected('Quarta', time.hour)">
                <p>{{ time.hour }}</p>
                <p>
                  <b>Voluntáriado</b>
                </p>
                <p>
                  <small>{{ attributeOffer.offerName }}</small>
                </p>
              </div>
            </td>
            <td>
              <div class="box-date" v-if="isSelected('Quinta', time.hour)">
                <p>{{ time.hour }}</p>
                <p>
                  <b>Voluntáriado</b>
                </p>
                <p>
                  <small>{{ attributeOffer.offerName }}</small>
                </p>
              </div>
            </td>
            <td>
              <div class="box-date" v-if="isSelected('Sexta', time.hour)">
                <p>{{ time.hour }}</p>
                <p>
                  <b>Voluntáriado</b>
                </p>
                <p>
                  <small>{{ attributeOffer.offerName }}</small>
                </p>
              </div>
            </td>
            <td>
              <div class="box-date" v-if="isSelected('Sábado', time.hour)">
                <p>{{ time.hour }}</p>
                <p>
                  <b>Voluntáriado</b>
                </p>
                <p>
                  <small>{{ attributeOffer.offerName }}</small>
                </p>
              </div>
            </td>
            <td>
              <div class="box-date" v-if="isSelected('Domingo', time.hour)">
                <p>{{ time.hour }}</p>
                <p>
                  <b>Voluntáriado</b>
                </p>
                <p>
                  <small>{{ attributeOffer.offerName }}</small>
                </p>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";

export default {
  props: {
    dialog: Boolean,
    volunteering: Object,
    attributeOffer: Object,
    selectedRegistrationOffers: Object,
    times: Object,
    recurrentType: Object,
  },
  methods: {
    isSelected(day, time) {
      if (this.selectedRegistrationOffers.length > 0) {
        return this.selectedRegistrationOffers.some((registration) => {
          return registration.volunteeringRegistrationOfferWeekDayHours.some(
            (weekDayHours) => {
              return (
                weekDayHours.weekDayName == day &&
                moment(weekDayHours.dayHour).format("HH:mm") == time
              );
            }
          );
        });
      }
      return false;
    },
    getRecurrentLimitDateText(recurrentType) {
      return moment(recurrentType.volunteeringDate).format("DD/MM/YYYY");
    },
    print() {
      if (document.getElementById != null) {
        var html = "<HTML>\n<HEAD>\n";
        if (document.getElementsByTagName != null) {
          var headTags = document.getElementsByTagName("head");
          if (headTags.length > 0) html += headTags[0].innerHTML;
        }

        html += `<style type="text/css" media="print">  @page { size: landscape; } </style>`;

        html += "\n</HE" + "AD>\n<BODY>\n";
        var printReadyElem = document.getElementById("printMeCalendar");

        var paper = `<div class="paper" style="background: #fff; margin: 0; padding:0; min-height: unset; min-width: unset; max-width: unset; width: 100%">${printReadyElem.innerHTML}</div>`;
        var printModal = `<div class="print-modal" style="width: 800px;">${paper}</div>`;
        var volunteering = `<div class="volunteering" style="width: 800px;">${printModal}</div>`;

        if (printReadyElem != null) html += volunteering;
        else {
          alert("Error, no contents.");
          return;
        }

        html += "\n</BO" + "DY>\n</HT" + "ML>";
        var printWin = window.open("", "processPrint");
        printWin.document.open();
        printWin.document.write(html);
        printWin.document.close();

        window.setTimeout(function () {
          printWin.print();
        }, 1000);
      } else alert("Browser not supported.");
    },
  },
};
</script>
